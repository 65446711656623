<template>
  <b-modal v-model="isModalOpen" :can-cancel="false" :width="650">
    <div class="cookie-modal">
      <div class="cookie-modal__body">
        <div class="cookie-modal__image"></div>

        <div class="cookie-modal__content">
          <div v-if="!otherCookieSettings">
            <p class="is-size-4 has-text-weight-bold">
              {{ $t("af:cookie_modal.title") }}
            </p>
            <p class="cookie-text">
              {{ $t("af:cookie_modal.text") }}
            </p>
          </div>
          <div v-else>
            <p class="is-size-4 has-text-weight-bold">
              {{ $t("af:cookie_modal.settings.title") }}
            </p>
            <div>
              <div class="switch">
                <div class="switch-label">
                  <p class="switch-label-text has-text-weight-bold">
                    {{ $t("af:cookie_modal.necessery_cookies") }}
                  </p>
                  <p class="switch-label-desc is-size-7">
                    {{ $t("af:cookie_modal.necessery_cookies.description") }}
                  </p>
                </div>
                <b-switch v-model="necesseryCookies" disabled></b-switch>
              </div>
              <div class="switch mt-5">
                <div class="switch-label">
                  <p class="switch-label-text has-text-weight-bold">
                    {{ $t("af:cookie_modal.analytics_cookies") }}
                  </p>
                  <p class="switch-label-desc is-size-7">
                    {{ $t("af:cookie_modal.analytics_cookies.description") }}
                  </p>
                </div>
                <b-switch v-model="analyticsCookies"></b-switch>
              </div>
            </div>
          </div>
          <div class="content-footer">
            <b-button
              id="CookieModalButtonAcceptAll"
              type="is-green"
              class="has-text-weight-medium"
              expanded
              @click="setCookiePreferences(true, true)"
            >
              {{ $t("af:cookie_modal.accept_all") }}
            </b-button>
            <b-button
              v-if="!otherCookieSettings"
              type="is-text"
              class="mt-3"
              expanded
              @click="openOtherCookieSettings"
            >
              {{ $t("af:cookie_modal.other_cookie_settings") }}
            </b-button>
            <b-button
              v-if="otherCookieSettings"
              type="is-text"
              class="mt-3"
              expanded
              @click="setCookiePreferences(true, analyticsCookies)"
            >
              {{ $t("af:cookie_modal.only_accept_selected") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "CookieConsentModal",
  data() {
    return {
      isModalOpen: false,
      otherCookieSettings: false,
      necesseryCookies: true,
      analyticsCookies: false,
    };
  },
  props: {},
  methods: {
    setCookiePreferences(necessery, analytics) {
      localStorage.setItem("allowNecesseryCookies", necessery);
      localStorage.setItem("allowAnalyticsCookies", analytics);
      if (analytics) {
        window.gtag("consent", "default", {
          analytics_storage: "granted",
        });

        this.$gtag.query("consent", "default", {
          analytics_storage: "granted",
        });
      } else {
        window.gtag("consent", "default", {
          analytics_storage: "denied",
        });

        this.$gtag.query("consent", "default", {
          analytics_storage: "denied",
        });
      }
      this.isModalOpen = false;
    },
    openOtherCookieSettings() {
      this.otherCookieSettings = true;
    },
  },
  mounted() {
    if (!localStorage.getItem("allowNecesseryCookies")) {
      this.isModalOpen = true;
    }
  },
};
</script>

<style scoped lang="scss">
.cookie-modal {
  width: 100%;
  // background: white;
  border-radius: 24px;
  position: relative;
  color: #051c3a;

  &__body {
    display: flex;
    flex-direction: column;
    align-content: space-between;
  }

  &__image {
    width: 100%;
    min-height: 30vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../assets/images/cookie.jpeg");
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  &__content {
    width: 100%;
    padding: 32px;
    background: white;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    .switch {
      display: grid;
      grid-template-areas:
        "text switch"
        "desc switch";
      grid-template-columns: auto min-content;
      width: 100%;

      & * {
        margin: 0;
      }

      &-label {
        &-text {
          grid-area: text;
          margin-bottom: 5px;
        }
        &-desc {
          grid-area: desc;
        }
      }

      b-switch {
        display: block;
        grid-area: switch;
      }
    }

    .cookie-text {
      font-size: 0.95em;
    }

    :nth-last-child(2) {
      flex-grow: 1;
    }

    .content-footer {
      margin-top: 32px;
      flex-grow: 0;
    }
  }
}

@media only screen and (min-width: 769px) {
  .cookie-modal {
    &__body {
      display: flex;
      flex-direction: row;
      align-content: space-between;
      max-height: min-content;
    }

    &__image {
      width: 40%;
      border-top-right-radius: 0;
      border-bottom-left-radius: 24px;
    }

    &__content {
      padding: 48px !important;
      padding-bottom: 32px !important;
      width: 60%;
      border-bottom-left-radius: 0;
      border-top-right-radius: 24px;
    }
  }
}
</style>
